.navigation-button {
  --border-radius: 4rem;

  @apply hidden flex-shrink-0 text-typography-green mr-4 py-12 px-6 bg-floor-dark-600;

  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);

  &[disabled] {
    @apply text-opacity-25 cursor-default text-typography-white bg-floor-dark-800 bg-opacity-40;
  }

  &:global(.right) {
    @apply mr-0 ml-4 rounded-r-none;

    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
}

@screen lg {
  .navigation-button {
    @apply block;
  }
}
