.main {
  @apply bg-right-top bg-no-repeat;
  background-image: url('/images/city_header.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.down {
  --implement-space: 6rem;
  position: relative;
  margin-top: calc(var(--implement-space) * -2);
}

.down::before {
  @apply w-full  bg-floor-green;
  content: '';
  height: 100px;
  position: absolute;
  bottom: -100px;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 10vw));

  @media (max-width: 1200px) {
    bottom: -50px;
  }
}

.down-wrapper {
  @apply pt-44 pb-28 bg-gradient-to-r from-floor-dark-700 to-floor-dark-500;

  margin-top: calc((14rem * 0.6) * -1);
}

.grow-green {
  @apply w-full relative bg-floor-green h-56 md:h-80;

  /* height: 20rem; */
  clip-path: polygon(0 40%, 100% 0, 100% 60%, 0% 100%);
}

.device {
  @media (max-width: 768px) {
    display: none;
  }
}

@screen sm {
  .car {
    @apply max-w-4xl;
    bottom: -50px;
  }
}

@screen md {
  .car {
    @apply max-w-7xl;
    bottom: -80px;
  }
}

@screen lg {
  .car {
    @apply max-w-screen-lg;
    bottom: -80px;
  }
}

@screen 2xl {
  .main > div:global(.content) {
    min-height: 80rem;
  }
}
