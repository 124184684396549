.down {
  --implement-space: 6rem;

  /* @apply border-b-4 border-t-4 border-floor-green; */
  position: relative;

  /* margin-top: calc(var(--implement-space) * -2); */
}

.down::before {
  @apply w-full  bg-floor-green;
  content: '';
  height: 100px;
  position: absolute;
  bottom: -100px;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 10vw));

  @media (max-width: 1200px) {
    bottom: -50px;
  }
}
