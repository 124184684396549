.navigation-button {
  @apply hidden flex-shrink-0 text-typography-green;

  &[disabled] {
    @apply text-typography-black text-opacity-25 cursor-default;
  }
}

@screen lg {
  .navigation-button {
    @apply block;
  }
}
